/** @format */

import React from "react";
import "../../App.css";
import ContactInfo from "../ContactInfo";

function Contact() { 
  return (
    <>
      <ContactInfo />
    </>
  );
}

export default Contact;
