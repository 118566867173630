import "../../App.css";
import MainSection from "../MainSection";
import React from "react";

function Home() { 
  return (
    <>
      <MainSection />
    </>
  );
}

export default Home;
